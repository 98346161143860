<template>
  <div class="content-container">
    <PageTitle :title="$t('SCHOOLS')" class="page-title" />

    <h1 class="page-section-title section-title">{{ $t('SCHOOL_DETAILS.DETAILS') }}</h1>
    <BackButton :text="$t('BACK_OVERVIEW')" :route-name="ROUTE_NAMES_HELPDESK.SCHOOL_OVERVIEW"/>

    <!-- School detail fields -->
    <div class="school-details-box">
      <UserDataField :field-title="$t('NAME')" :user-data="currentSchool.name" class="data-item"/>
      <UserDataField :field-title="$t('ADDRESS')" :user-data="currentSchool.address" class="data-item"/>
      <UserDataField :field-title="$t('BRIN_NUMBER')" :user-data="currentSchool.brin" class="data-item">
        <template #content>
          <InfoTooltip :text="$t('SCHOOL_DETAILS.BRIN_TOOLTIP')" class="brin-tooltip"/>
        </template>
      </UserDataField>
      <div class="edit-details">
        <BasicButton :button-text="$t('EDIT_DATA')" @click="editSchool()"/>
      </div>
    </div>

    <!-- Linked Classes -->
    <h1 class="page-section-title section-title">{{ $t('SCHOOL_DETAILS.LINKED_CLASSES') }}</h1>
    <CustomTable v-if="linkedClasses.length > 0"
                 :options="linkedClassesOptions"
                            :table-data="linkedClasses"
                 :less-padding="true"
                 table-class="schools-details-table linked-classes"
                 class="linked-table">
      <template #name="props">
        <div>{{ props.rowData.name }}</div>
      </template>
      <template #group="props">
        <div>{{ props.rowData.classTypeName }}</div>
      </template>
      <template #totalStudents="props">
        <div>{{ props.rowData.studentCount }}</div>
      </template>
      <template #actions="props">
        <div class="action-button-group">
          <ActionButton :text="$t('VIEW')"
                        :icon="require('../../../assets/icons/icn_visibility_white.svg')"
                        custom-class="button-blue"
                        @click="viewClassDetails(props.rowData)"/>
        </div>
      </template>
    </CustomTable>

    <!-- Linked Teachers -->
    <h1 class="page-section-title section-title">{{ $t('LINKED_TEACHERS') }}</h1>
    <CustomTable v-if="linkedTeachers.length > 0"
                 :options="linkedTeachersOptions"
                            :table-data="linkedTeachers"
                 :less-padding="true"

                 table-class="schools-details-table linked-teachers"
                 class="linked-table">
      <template #name="props">
        <div>{{ props.rowData.name }}</div>
      </template>
      <template #actions="props">
        <div class="action-button-group">
          <ActionButton :text="$t('VIEW')"
                        :icon="require('../../../assets/icons/icn_visibility_white.svg')"
                        custom-class="button-blue"
                        @click="viewTeacherDetails(props.rowData)"/>
        </div>
      </template>
    </CustomTable>

    <!-- Delete School Warning -->
    <DeleteSchoolWarning v-if="activeBasicModal === MODAL_NAMES.DELETE_SCHOOL" ref="deleteWarningModal"/>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import BackButton from '@/components/elements/BackButton'
import UserDataField from '@/components/elements/settings/UserDataField'
import { ROUTE_NAMES_HELPDESK } from '@/router/modules/helpdesk'
import { useRoute, useRouter } from 'vue-router'
import { getTableOptions, LINKED_CLASSES, LINKED_TEACHERS } from '@/utils/helpers/customTable/CustomTableHelper'
import ActionButton from '@/components/table/ActionButton'
import { computed, ref } from '@vue/reactivity'
import CustomTable from '@/components/table/CustomTable'
import { useStore } from 'vuex'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { RESET_BASIC_MODAL_DATA, SET_BASIC_MODAL_DATA } from '@/store/mutations'
import DeleteSchoolWarning from '@/components/elements/helpdeskSchools/DeleteSchoolWarning'
import InfoTooltip from '@/components/elements/InfoTooltip'
import ApiHandler from '@/utils/handlers/ApiHandler'
import BasicButton from '@/components/elements/basic/BasicButton'

export default {
  name: 'SchoolDetails',
  components: {
    InfoTooltip,
    DeleteSchoolWarning,
    CustomTable,
    ActionButton,
    UserDataField,
    BackButton,
    PageTitle,
    BasicButton
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const schoolId = route.params.schoolId
    const activeBasicModal = computed(() => store.getters.getActiveBasicModal)

    const currentSchool = ref({})


    function editSchool() {
      router.push({ name: ROUTE_NAMES_HELPDESK.SCHOOL_EDIT, params: { schoolId: schoolId } })
    }

    // todo fix hardcoded data
    ApiHandler.get(`helpdesk/school/get-school-details/${schoolId}`)
      .then(response => {
        if (response) {
          currentSchool.value = response.data
          return true
        }
        return false
      })
      .catch(error => {
        console.log(error)
      })

    /** Delete school **/
    const deleteWarningModal = ref(null) // The ref of the Vue component so we can access it.

    function deleteSchoolWarning() {
      const payload = {
        modalOptions: {
          modalContent: BASIC_MODAL_CONTENT.DELETE_SCHOOL('OBS de Vondelschool'),
          cancelCallback: null,
          executeCallback: onSchoolDeletion
        },
        activeModal: MODAL_NAMES.DELETE_SCHOOL
      }
      // After successful validation, the user should see a warning popup.
      store.commit(SET_BASIC_MODAL_DATA, payload)
    }

    function onSchoolDeletion() {
      // Check if the checkboxes have been all clicked. If they are not, an error is automatically displayed.
      const areAllCheckboxesChecked = deleteWarningModal.value.validateCheckboxes()
      if (areAllCheckboxesChecked) {
        ApiHandler.delete(`helpdesk/school/delete-school/${schoolId}`).then(response => {
          if (response) {
            router.push({ name: ROUTE_NAMES_HELPDESK.SCHOOL_OVERVIEW })
            store.commit(RESET_BASIC_MODAL_DATA)
          }
        })
      }
    }


    /** Table Actions **/
    function viewClassDetails(clickedClass) {
      router.push({ name: ROUTE_NAMES_HELPDESK.CLASS_DETAILS, params: { classId: clickedClass.id } })
    }

    /** Table Actions **/
    function viewTeacherDetails(clickedTeacher) {
      router.push({ name: ROUTE_NAMES_HELPDESK.TEACHER_DETAILS, params: { teacherId: clickedTeacher.id } })
    }

    /** Linked Classes **/
    const linkedClasses = computed(() => {
      return currentSchool.value.linkedClasses ?? []
    })


    /** Linked Teachers **/
    const linkedTeachers = computed(() => {
      return currentSchool.value.linkedTeachers ?? []
    })

    return {
      currentSchool,
      ROUTE_NAMES_HELPDESK,
      schoolId,
      activeBasicModal,
      MODAL_NAMES,

      /** Delete School **/
      deleteWarningModal,
      deleteSchoolWarning,

      /** Table **/
      linkedClassesOptions: getTableOptions(LINKED_CLASSES),
      linkedTeachersOptions: getTableOptions(LINKED_TEACHERS),

      /** Table Actions **/
      viewClassDetails,
      viewTeacherDetails,
      editSchool,

      /** Linked Classes **/
      linkedClasses,

      /** Linked Teachers **/
      linkedTeachers,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.school-details-box {
  margin-top: rem(24);
  width: rem(746);

  .data-item {
    &:not(:last-of-type) {
      margin-bottom: rem(16);
    }
  }

  .edit-details {
    margin-bottom: rem(56);
    margin-top: rem(40);
  }
}

.brin-tooltip {
  @include position(absolute, $top: 50%, $right: rem(25));
  transform: translateY(-50%);
}

.linked-table {
  margin-bottom: rem(32);
}

.delete-button {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: rem(10) rem(21);
  //width: rem(236);

  .icon {
    margin-right: rem(15);
    width: rem(15);
  }
}
</style>
